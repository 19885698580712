export default () => {
  (function() {
    // Function to initialize each video embed
    const initializeVideoEmbed = videoContainer => {
      const iframe = videoContainer.querySelector('iframe');

      // Ensure the iframe exists
      if (!iframe)
        return;

      // Handle poster click event
      videoContainer.addEventListener('click', () => {
        // Set a data attribute on the container to signal that the video is playing
        videoContainer.setAttribute('data-playing', 'true');

        // Trigger autoplay by appending autoplay to the iframe src if not already there
        const src = iframe.getAttribute('src');
        if (!src.includes('autoplay=1')) {
          iframe.setAttribute('src', `${src}&autoplay=1`);
        }
      });
    };

    // Select all video containers with the js-video-embed class on the page
    const videoContainers = document.querySelectorAll('.js-video-embed');

    // Initialize all video embeds
    videoContainers.forEach(container => {
      initializeVideoEmbed(container);
    });
  })();
};
